<template>
  <svg viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8346 3.25033C12.8346 2.65449 12.3096 2.16699 11.668 2.16699H2.33464C1.69297 2.16699 1.16797 2.65449 1.16797 3.25033M12.8346 3.25033V9.75033C12.8346 10.3462 12.3096 10.8337 11.668 10.8337H2.33464C1.69297 10.8337 1.16797 10.3462 1.16797 9.75033V3.25033M12.8346 3.25033L7.0013 7.04199L1.16797 3.25033"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
